$theme-color-primary: #26B096;
$theme-color-secondary: #6c757d;
$theme-color-success: #198754;
$theme-color-warning: #ffc107;
$theme-color-info: #0dcaf0;
$theme-color-danger: #dc3545;

:root {
  --theme-color-primary: #{$theme-color-primary};
  --theme-color-secondary: #{$theme-color-secondary};
  --theme-color-success: #{$theme-color-success};
  --theme-color-warning: #{$theme-color-warning};
  --theme-color-info: #{$theme-color-info};
  --theme-color-danger: #{$theme-color-danger};
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FAFAFA !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.siq_bL.zsiq_theme1 .zsiq_flt_rel,
.siq_lM.zsiq_theme1 .zsiq_flt_rel,
.siq_tL.zsiq_theme1 .zsiq_flt_rel {
  /* float: right !important; */
  background-color: #26B096 !important;
  /* border-radius: 50%;
      position: fixed;
      bottom: 20px;
      right: 20px;
      z-index: 1000; */
}

.image_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text_content {
  display: block;
  text-align: left;
  margin-left: 10px;
  margin-right: 10px;
  color: grey;
}

.menu_head_style {
  font-size: 20px !important;
}

.menu_nav_link {
  text-decoration: none !important;
}

.details_style {
  margin-bottom: 10px !important;
  border-bottom: 1px dashed #868686 !important;
}

.details_question_style {
  font-size: 13px !important;
  font-weight: 600 !important;
  margin-bottom: 10px;
}

.details_style_para {
  margin-left: 15px !important;
  font-size: 13px !important;
}

.no_data_found {
  width: 100% !important;
  height: 300px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.button_style_active,
.button_style_active:hover {
  background-color: #00929C !important;
  border: none !important;
  border-radius: 3px !important;
  color: #ffffff !important;
  padding: 8px !important;
  width: 100% !important;
  font-size: 12px !important;
  height: 53px;
}

//KB styles
.main_container_style {
  width: 100% !important;
  margin: auto !important;
  position: relative;
  top: -176px;
}

.flex-direction {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.topbar_header {
  @extend .flex-direction;
  background-color: #ffffff;
  padding: 20px 25px 20px 25px;
  height: 100px
}


.topbar_menu_list_style {
  @extend .flex-direction;
  padding: 0;
  column-gap: 10px !important;
  display: none !important;
}

.active_menu_bar {
  color: #121212 !important;
  border: none !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  background-color: #FAFAFA !important;
  padding: 0px;
}

.content_body {
  margin-top: -10px !important;
  padding: 20px;
}

.menu_bar_style {
  color: #121212 !important;
  border: none !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  text-align: left !important;
  background-color: transparent !important;
}

.dl,
ol,
ul {
  margin: 0px !important;
}

.active_menu_bar .lucide {
  transform: rotate(180deg) !important;
  transition: transform 0.4s ease !important;
  font-size: 15px !important;
}

.sub_menu_style {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  column-gap: 20px !important;
  margin-top: 10px !important;
}

.contact_us_style {
  background-color: transparent !important;
  font-size: 15px !important;
  font-weight: 700 !important;
  letter-spacing: 0.03em !important;
  text-align: center !important;
  color: #00929C !important;
  border: 1px solid #00929C !important;
}

.lucide-chevron-down {
  width: 15px !important;
}

.heading_style {
  font-size: 15px !important;
  font-weight: 600;
}

.menu_item {
  height: 100% !important;
}

.hide-chevron .lucide-chevron-down {
  display: none;
}

.card_container {
  background-color: #E4D9F8 !important;
  height: 100% !important;
}

h1 {
  font-size: 40px !important;
  font-weight: 700 !important;
}

h2 {
  font-size: 20px !important;
  font-weight: 700 !important;
}

//home screen banner
.home_screen_container {
  width: 100vw !important;
  height: calc(100vh - 75px) !important;
  z-index: -9999;
}

.home_screen_image {
  object-fit: cover !important;
}


.custom_curosel_image_container {
  position: relative;
}

.custom_content_style {
  position: absolute;
  z-index: 1;
  top: 0 !important;
  left: 50px !important;
}

.custom_content_style_highlighted_article {
  background-color: transparent !important;
  color: #ffffff !important;
  border: none !important;
}

.custom_content_style_description {
  font-weight: 300 !important;
  width: 70% !important;
}

.home_main_card {
  height: 300px !important;
  margin: auto !important;
  width: 100% !important;
  background-color: #E4D9F8 !important;
}

.main_card_col_style {
  width: 95% !important;
  margin: auto !important;
  min-height: 450px !important;
}

.banner_card_style {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  margin-top: 30px !important;
}

.image_card_container {
  height: 300px !important;
  width: 80% !important;
}

.top_news_heading {
  font-size: 25px !important;
  margin: 20px !important;
}

.dot_icon_style {
  font-size: 15px !important;
  color: #26B096 !important;
}

.horizontal_line {
  width: 50vw !important;
  border: 1px solid;
}

.sub_each_card_reels_style {
  margin-top: 30px !important;
}

.video_each_card_kbreels_style {
  height: 200px !important;
  width: 80vw !important;
}

.video_list_menu_style {
  margin-top: 20px !important;
  width: 100% !important;
}

@media (min-width:768px) {
  .horizontal_line {
    width: 64vw !important;
  }

  .video_list_menu_style {
    // width:100vw !important; 
  }
}

@media (min-width:992px) {
  .horizontal_line {
    width: 68vw !important;

  }

  .video_list_menu_style {
    width: 100% !important;
    height: 300px !important;
  }

}

//embla css
.slider_container {
  width: 90% !important;
  height: 200px !important;
}

.carousal_slide {
  position: relative;
  z-index: 2;
  top: -220px !important;
  background-color: #ffffff !important;
  width: 95% !important;
  margin: auto !important;
  border-radius: 10px !important;
  min-height: 250px !important;
  padding: 0px 10px !important;
  // border:1px solid red !important;
}

.slick-prev:before,
.slick-next:before {
  color: #26B096 !important;
}

.slick-slide {
  height: 200px !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
}

.slider_container_sub {
  height: 200px !important;
}

.slide_style {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  width: 120px !important;
  height: 120px !important;
  border-radius: 50% !important;
  box-shadow: 1px 10px 15px 10px #7C7C7C40;
  border: 1px solid #26B096;
}

.top_picks_card_style {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px !important;
}

.top_heading_style {
  font-size: 20px !important;
  white-space: nowrap !important;
}

.top_picks_slide_style {
  height: 100% !important;
}

.heading_style {
  font-size: 15px !important;
  text-align: center !important;
  margin-top: 14px !important;
}

.recent_news_card_style {
  margin-top: 20px !important;
  min-height: 700px !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-around;
  align-items: flex-start !important;
  width: 100%;
  margin: auto !important;
}

////recent news 
.data1_image_card_style {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
}

.data1_image_style {
  position: relative;
  width: 96% !important;
  margin-bottom: 20px !important;
  align-self: center !important;
  height: 200px !important;
  // border: 1px solid red !important;
}

.data_first_image {
  position: absolute !important;
  // margin-top: -150px !important;
  left: 0 !important;
}

.title_sub {
  font-size: 12px !important;
  // margin-bottom: 20px !important;
  align-self: flex-start !important;
  background-color: #00929C !important;
  color: #ffffff !important;
  border: none !important;
  cursor: auto !important;
}

.first_image_content {
  width: 90% !important;
  margin-bottom: 20px !important;
  align-self: center !important;
  font-size: 14px !important;
}

.sub_card_style {
  // height: 200px !important;
  width: 90% !important;
  margin-bottom: 20px !important;
}

.small_device_image {
  position: relative;
  height: 200px !important;
  padding: 0 !important;
}

.small_device_image_button {
  display: block;
  position: absolute;
  top: 0 !important;
}

.small_device_image_button_large {
  display: none !important;
}

.data2_image_style {
  width: 90% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  margin: auto !important;
  row-gap: 10px !important;
  font-size: 14px !important;
}

.data3_image_row_style {
  margin: auto !important;
}

.data3_image_style {
  width: 90% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  margin: auto !important;
  row-gap: 10px !important;
  font-size: 14px !important;
}
.data4_image_style {
  // width: 90% !important;
  // display: flex !important;
  // flex-direction: column !important;
  // justify-content: center !important;
  // align-items: center !important;
  margin: auto !important;
  // row-gap: 10px !important;
  // font-size: 14px !important;
}

@media (min-width:576px) {
  .data1_image_card_style {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
  }

  .data1_image_style {
    width: 96% !important;
    height: 200px !important;
    margin-bottom: 20px !important;
    align-self: center !important;
  }

  .data2_image_style {
    font-size: 16px !important;
  }

  .data_first_image {
    // margin-top: -150px !important;
    left: 0 !important;
  }

  .title_sub {
    font-size: 15px !important;
    margin-bottom: 20px !important;
    align-self: flex-start !important;
    background-color: #00929C !important;
    color: #ffffff !important;
    border: none !important;
    cursor: auto !important;
  }

  .first_image_content {
    width: 90% !important;
    margin-bottom: 20px !important;
    align-self: center !important;
    font-size: 16px !important;
  }

  .sub_card_style {
    // height: 200px !important;
    margin-bottom: 20px !important;
  }

  .data3_image_style {

    font-size: 16px !important;
  }
}

@media (min-width:768px) {
  .data1_image_card_style {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
  }

  .data1_image_style {
    width: 96% !important;
    height: 500px !important;
    margin-bottom: 20px !important;
    align-self: center !important;
  }

  .data2_image_style {
    font-size: 16px !important;
  }

  .data_first_image {
    // margin-top: -150px !important;
    bottom: 0 !important;
  }

  .title_sub {
    font-size: 15px !important;
    margin-bottom: 20px !important;
    align-self: flex-start !important;
    background-color: #00929C !important;
    color: #ffffff !important;
    border: none !important;
    cursor: auto !important;
  }

  .first_image_content {
    width: 90% !important;
    margin-bottom: 20px !important;
    align-self: center !important;
    font-size: 16px !important;
  }

  .sub_card_style {
    height: 200px !important;
    margin-bottom: 20px !important;
  }

  .small_device_image_button {
    display: none !important;
  }

  .small_device_image_button_large {
    display: block !important;
  }

  .data3_image_style {
    width: 94% !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    justify-content: flex-start !important;
    font-size: 16px !important;
  }
  .horizontal_line_individual {
    display: none !important;
  }

}

.home_heading_image {
  font-size: 16px !important;
 
}
.share_home_heading_image{
  font-size: 16px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  width:100% !important;
}

.image_thumbnail_icon {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  margin-right: 10px !important;
  border: 3px solid transparent !important;
  background-image: linear-gradient(white, white),
    linear-gradient(to right, #ffcc33, #209fa8);
  background-origin: border-box;
  background-clip: content-box, border-box;
  
}

.share_icon_style_card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px !important;
  color: #475467 !important;
  width: 100% !important;
  margin-top: 10px !important;
}



.title_sub1 {
  background-color: #F2C94C !important;
}

.title_sub2 {
  background-color: #EB5757 !important;
}

.title_sub3 {
  background-color: #27AE60 !important;
}

.title_sub4 {
  background-color: #4C4C4C !important;
}

.iframe_list_menu_style {
  width: 100% !important;
  height: 100% !important;
}



.paragraph_container {
  height: 100px !important;
  width: 100% !important;
  white-space: wrap !important;
  overflow-y: scroll !important;
  text-overflow: ellipsis !important;
  display: inline-block;
  scrollbar-width: none !important;
}

.trending_news_style {
  margin-bottom: 20px !important;
}

//reels 
.input_card {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #E4D9F8;
  border-radius: 5px !important;
  padding: 0px 5px !important;
  width: 70% !important;
  background-color: #ffffff !important;
}

.catalogue_orders_styles_card {
  width: 100% !important;
  height: 100% !important;
}

.catalogue_orders_style {
  width: 100% !important;
  height: 100% !important;
  padding: 8px !important;
  outline: none;
  background-color: transparent !important;
  border: none !important;
}

.category_card_style {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(90px, 1fr)) !important;
  width: 100% !important;
  column-gap: 30px !important;
  row-gap: 20px !important;
  margin-bottom: 52px !important;
  margin-top: 50px !important;
}

.inactive_category_button_style {
  border: 1px solid #E4D9F8 !important;
  background-color: transparent !important;
  color: #121212 !important;
  min-width: 50px !important;
}

.active_category_button_style {
  border: 1px solid #E4D9F8 !important;
  background-image: linear-gradient(to bottom, #00B99C, #00929C, ) !important;
  color: #ffffff !important;
  min-width: 60px !important;
}

.shorts_heading_style {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  column-gap: 10px !important;
  font-size: 28px !important;
  font-weight: 700;
  margin-bottom: 47px !important;
}

.shorts_icon_style {
  font-size: 28px !important;
  color: #475467 !important;
}

// loader CSS
.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 99;
}

.spinner {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
}

.spinner:before,
.spinner:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}

.spinner:before {
  animation: ball1 1s infinite;
  background-color: var(--theme-color-primary);
  box-shadow: 30px 0 0 var(--theme-color-primary);
  margin-bottom: 10px;
}

.spinner:after {
  animation: ball2 1s infinite;
  background-color: var(--theme-color-primary);
  box-shadow: 30px 0 0 var(--theme-color-primary);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) scale(0.8)
  }

  50% {
    transform: rotate(360deg) scale(1.2)
  }

  100% {
    transform: rotate(720deg) scale(0.8)
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 var(--theme-color-primary);
  }

  50% {
    box-shadow: 0 0 0 var(--theme-color-primary);
    margin-bottom: 0;
    transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 var(--theme-color-primary);
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 var(--theme-color-primary);
  }

  50% {
    box-shadow: 0 0 0 var(--theme-color-primary);
    margin-top: -20px;
    transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 var(--theme-color-primary);
    margin-top: 0;
  }
}

.slick-initialized .slick-slide {
  height: 100% !important;
}

.reels_image1 {
  height: 100% !important;
  width: 100% !important
}

.iframe_list_menu_style1 {
  height: 500px !important;
  width: 100% !important;
}

.slider_container1 {
  margin: auto !important;

}




.top_picks_slide_style1 {
  max-width: 350px !important;
  max-height: 357px !important;
  border-radius: 4px !important;
  box-shadow: 1px 1px 6px #00000040 !important;
  border: 1px solid #E4D9F8;
}

@media (min-width:567px) {
  .top_picks_slide_style1 {
    max-width: 250px !important;
  }
}

@media (min-width:567px) {
  .top_picks_slide_style1 {
    max-width: 300px !important;
  }
}


.description_style {
  font-size: 14px !important;
}

.view_more_button {
  color: blue;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 0.9em;
  text-decoration: underline;
}

.slick-track {
  height: 400px !important;
}

.video_clip_card {
  width: 100% !important;
  margin: auto !important;
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)) !important;
  margin: auto;
  // display: flex !important;
  // align-items: flex-start !important;
  // justify-content: space-between !important;
  padding: 20px !important;
}

.video_clip_style {
  width: 100% !important;
  border-radius: 4px !important;
  box-shadow: 1px 1px 6px #00000040 !important;
  padding-bottom: 20px;
  font-size: 14px !important;
}

@media (min-width:576px) {
  .video_clip_card {
    width: 100% !important;
    margin: auto !important;
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)) !important;
    column-gap: 15px !important;
    margin: auto !important;
    padding: 20px !important;
  }

  .video_clip_style {
    width: 100% !important;
    // border-radius: 4px !important;
    // box-shadow: 1px 1px 6px #00000040 !important;
    // padding-bottom: 20px;
    font-size: 15px !important;
  }
}

.video_clip_profile_style {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 20px;
  font-size: 15px;
}

.main_card_container {
  display: flex !important;
  flex-direction: column !important;
  row-gap: 20px !important;
  padding-bottom: 20px !important;
  margin: auto !important;
}

.each_media_style {
  height: 70vh !important;
  width: 90vw !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}

.each_media_row_style {
  height: 750px !important;
  min-width: 1000px !important;
}

.each_card_reels_style {
  height: 80vh !important;
  width: 1000px !important;
  margin: auto !important;
}

.reels_banner_description {
  // display: -webkit-box;
  overflow: hidden;
  height: auto;
  max-height: 100px !important;
  font-size: 15px !important;
  text-overflow: ellipsis !important;

}

.slick-initialized .slick-slide {
  height: 100% !important;
}

.video_clip_icon_style {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: 100% !important;
}


.slider_container_sub1 {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  padding: 15px !important;
  font-size: 14px !important;
}

@media (min-width:576px) {
  .slider_container_sub1 {
    font-size: 15px !important;
  }
}

@media (min-width:768px) {
  .slider_container_sub1 {
    font-size: 16px !important;
  }
}



.description_style {
  font-size: 14px !important;
}

.shorts_description_style {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;
  max-height: 4.5em;
}

.reels_banner_description {
  // display: -webkit-box;
  // -webkit-box-orient: vertical;
  // -webkit-line-clamp: 3;
  //* Change ellipsis to clip */

  overflow: hidden !important;
  height: 56px !important;
  // position: relative;
  // overflow: hidden;
}

.shorts_description_style.expanded {
  -webkit-line-clamp: unset;
  max-height: none;
}

.view_more_button {
  color: blue;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 0.9em;
  text-decoration: underline;
}

.slick-track {
  height: 400px !important;
}


// .video_clip_style {
//   width: 48% !important;
//   border-radius: 4px !important;
//   box-shadow: 1px 1px 6px #00000040 !important;
//   padding-bottom: 20px;
// }

.view_more_button_style {
  font-size: 15px !important;
  align-self: flex-end !important;
  width: 80px !important;
  margin-left: 5px !important;
  color: #5B21CB !important;
  cursor: pointer !important;
}

.video_clip_profile_style {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 20px;
  font-size: 15px;
}

.first_description_style {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;
  max-height: 4.5em;
}

.paragraph_container_for_home_recent_style {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;
  max-height: 4.5em;
}

.banner_image_style {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  width: 100% !important;
  height: 400px !important;
}

.image_style_for_banner {
  width: 100%;
}

.banner_description_style {
  margin: 20px !important;
}

.active_menu_style {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0px 5px !important;
  height: 30px !important;
}

.inactive_menu_style {
  text-decoration: none !important;
  @extend .active_menu_style;
}

.active_menu_nav_style {
  @extend .active_menu_style;
  text-decoration: none !important;
  color: #ffffff !important;
  background-color: #00929C !important;
  border-radius: 5px;
}

.inactive_menu_nav_style {
  @extend .active_menu_style;
  text-decoration: none !important;
  color: #000000 !important;
  border-radius: 5px;
}

.top_pick_slider_style {
  height: 10rem !important;
  width: 94% !important;
  margin: auto !important;
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
  // border: 1px solid red !important;
}

.top_pick_slide_card_style {
  margin-top: 20px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}

#top_slick_card_style {
  border-radius: 50% !important;
  height: 100px !important;
  width: 100px !important;
  position: absolute;
  transform: translateX(-50%);
  bottom: 70%;
}
#faq_style{
  height:400px !important;
  width: 400px !important;
  bottom: 70% !important;
  border: 1px solid red !important;
}
.faq_caurosel_image{
display: flex !important;
flex-direction: row !important;
align-items: center !important;
}
.details_view_style {
  width: 90% !important;
  height: 100% !important;
  margin: auto !important;
object-fit: cover !important;
}

.dropdown_style {
  display: block !important;
  background-color: #00929C !important;
  color: #ffffff !important;
  border: none !important;

}

@media (min-width: 576px) and (max-width: 768px) {
  body {
    background-color: #ffffff !important;
  }

  .image_container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .image_content {
    max-width: 90%;
    max-height: 90%;
  }

  .button_style_active {
    font-size: 12px !important;
    width: 100%;
    height: 53px;
  }

  .button_style_inactive {
    font-size: 11px !important;
    width: 100%;
    height: 53px;
  }

  .content_space {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .text_content {
    display: block !important;
    text-align: left !important;
    margin-left: 1rem !important;
    color: grey !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .contact_us_style {
    margin-left: 0px !important;
  }

  .topbar_header {
    padding: 20px 25px 20px 25px;
  }

  .topbar_menu_list_style {
    padding: 0;
    display: none !important;
  }

  .dropdown_style {
    display: block !important;
  }

  .active_menu_style {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0px 5px !important;

  }

  .inactive_menu_style {
    text-decoration: none !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0px 5px !important;
  }

  .active_menu_nav_style {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0px 5px !important;
    text-decoration: none !important;
    color: #ffffff !important;
    background-color: #00929C !important;
    border-radius: 5px;
  }

  .inactive_menu_nav_style {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0px 5px !important;
    text-decoration: none !important;
    color: #000000 !important;
    border-radius: 5px;
  }

  .content_space {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

}

@media (min-width:768px) {

  .image_container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .image_content {
    max-width: 90%;
    max-height: 90%;
  }

  .button_style_active {
    font-size: 12px !important;
    width: 100%;
    height: 53px;
  }

  .button_style_inactive {
    font-size: 11px !important;
    width: 100%;
    height: 53px;
  }

  .content_space {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .text_content {
    display: block !important;
    text-align: left !important;
    margin-left: 1rem !important;
    color: grey !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .contact_us_style {
    margin-left: 50px !important;
  }

  .topbar_header {
    padding: 20px 25px 20px 25px;
  }

  .topbar_menu_list_style {
    padding: 0;
    display: flex !important;
  }

  .dropdown_style {
    display: none !important;
  }

  .active_menu_style {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0px 5px !important;

  }

  .inactive_menu_style {
    text-decoration: none !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0px 5px !important;
  }

  .active_menu_nav_style {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0px 5px !important;
    text-decoration: none !important;
    color: #ffffff !important;
    background-color: #00929C !important;
    border-radius: 5px;
  }

  .inactive_menu_nav_style {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0px 5px !important;
    text-decoration: none !important;
    color: #000000 !important;
    border-radius: 5px;
  }

}

#btn-primary{
  width:100% !important;
  background-color: transparent !important;
  border: none !important;
  color: #000000 !important;
  font-size: 12px !important;
}
#btn-primary-active{
  background-color: #00929C !important;
  border: none !important;
  border-radius: 3px !important;
  color: #ffffff !important;
  padding: 15px !important;
  width: 100% !important;
  font-size: 12px !important;
}

.button_style_inactive {
  font-size: 11px !important;
  width: 100%;
  height: 53px;
}

#active_sub_menu{
  background-color: #00929C !important;
  border: none !important;
  border-radius: 3px !important;
  color: #ffffff !important;
  font-size: 14px !important;
}
#inactive_sub_menu{
  width:100% !important;
  background-color: transparent !important;
  border: none !important;
  color: #000000 !important;
  font-size: 14px !important;
}

.share_icon{
  width:25px !important;
  height: 25px !important;
  cursor: pointer !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 18px !important;
  color: #209fa8;
}
.share_icon:hover{
  width:25px !important;
  height: 25px !important;
  background-color: #E4D9F8;
  border: 1px solid #E4D9F8;
border-radius: 5px !important;
color: #5B21CB !important;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
font-size: 20px !important;
}
#overlayExample{
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  column-gap: 5px !important;
}
.overLay{
  width:200px !important;
  background-color: #000000 !important;
}
.share_targetScreen{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.reels_image_style{
  object-fit: cover !important;
}
.main_container_style1{
   padding: 0px 30px !important;
}

.faq_row_style{
  width:100% !important;
  height: 400px !important;
  margin: auto !important;
  text-align: center !important;
  margin-bottom: 10px !important;
}
.faq_slide_style{
  border: 1px solid blue !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: 100% !important;
}
.faq_slide_col_style{
  width: auto !important; /* Adjust to fit content */
  max-width: 200px !important;
}